import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('route-dialog',{attrs:{"title":"Message Info","loading":_vm.loading,"max-width":"1000"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"value":_vm._f("date")(_vm.message.date),"label":"Date","readonly":""}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"value":_vm.message.chargePointName,"label":"Charge Point","readonly":""}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"value":_vm.message.centralSystemName,"label":"Central System","readonly":""}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"value":_vm.message.messageType,"label":"Type","readonly":""}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"value":_vm.message.messageVersion,"label":"Version","readonly":""}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"value":_vm.message.messageAction,"label":"Action","readonly":""}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTabs,{attrs:{"grow":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTab,[_vm._v("ChargePoint Message")]),_c(VTab,[_vm._v("Processed Message")]),_c(VTab,[_vm._v("Formatted Message")]),_c(VTabItem,[_c('pre',[_vm._v(_vm._s(_vm._f("message")(this.message.chargePointMessage)))])]),_c(VTabItem,[_c('pre',[_vm._v("Headers:\n"+_vm._s(_vm._f("json")(this.message.headers))+"\nPayload:\n"+_vm._s(_vm._f("json")(this.message.payload))+"\n                            ")])]),_c(VTabItem,[_c(VTreeview,{attrs:{"items":_vm.items,"open":_vm.open,"open-on-click":""},on:{"update:open":function($event){_vm.open=$event}}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }