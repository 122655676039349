
    import {Component, Vue} from "vue-property-decorator";

    import RouteDialog from "../components/RouteDialog.vue";
    import {Message} from "@/domain";
    import moment from "moment";
    const pd = require('pretty-data').pd

    @Component({
        components: {RouteDialog},
        filters: {
            date(value: string) {
                return moment(value).format("YYYY-MM-DD hh:mm")
            },
            json(value: string) {
                return value != null ? JSON.stringify(JSON.parse(value), null, 2) : '';
            },
            message(value: string) {
              if (value != null && value.startsWith('<')) {
                return pd.xml(value)
              } else if (value != null && value.startsWith('[')) {
                return JSON.stringify(JSON.parse(value), null, 2)
              } else {
                return value;
              }
            }

        }
    })
    export default class InfoMessageDialog extends Vue {
        message: Partial<Message> = {};
        loading: boolean = true;
        activeTab = 0;

        items: any[] = [];
        open: number[] = [];

        async mounted() {
            this.message = await this.$store.dispatch("messages/fetchOne", this.$route.params.id);
            this.items = [];

            const headers = JSON.parse(this.message.headers!);
            const payload = JSON.parse(this.message.payload!);
            const chargePointMessage = this.message.chargePointMessage!;

            this.open = [2];

            this.items.push({
                id: 1,
                name: 'Headers',
                children: this.formatObject(headers, 10)
            });

            this.items.push({
                id: 2,
                name: 'Payload',
                children: this.formatObject(payload, 20, true)
            });

            this.loading = false;
        }

        formatObject(object: Object, indexOffset: number = 0, shouldOpen: boolean = false): any[] {
            return Object.entries(object).map(([key, val], index) => {
                if (shouldOpen) {
                    this.open.push(indexOffset + index);
                }
                return {
                    id: indexOffset + index,
                    name: key + (typeof val !== 'object' ? `: ${val}` : ''),
                    ...(typeof val === 'object' && { children: this.formatObject(val, indexOffset + index * 10, shouldOpen) })
                };
            });
        }
    }
